.serviceWorker {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;

  z-index: 4;

  align-items: center;
  justify-content: center;

  background-color: white;
  color: black;

  display: none;

  &.isVisible {
    display: flex;
  }

  .button {
    margin: 0 16px;
  }
}
