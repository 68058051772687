@import "adwarStyles";

.adwarMiniDisplay {
  @include adwarStyles;

  position: relative;

  &--openOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    cursor: pointer;
  }

  &.disabled {
    opacity: 0.5;
    .adwarMiniDisplay--openOverlay {
      cursor: default;
    }
  }
}
