@import "./variables.scss";

.button {
  outline: none;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  padding: 0 15px 3px 15px;
  border-radius: 30px;
  background: black;
  box-shadow: none;
  font-family: urw-din, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  color: $textColor;

  &:active,
  &.isActive {
    background: $textColor;
    color: $backgroundColor;
  }

  &:disabled {
    opacity: 0.25;
    cursor: default !important;
  }

  &--primary {
    background: #bde1f4;
    color: black;
    border-radius: 2px;
  }

  &--small {
    background: black;
    height: 20px;
    border-radius: 20px;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 10px;
  }

  &--inline {
    background: transparent;
    height: auto;
    border-radius: 20px;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 10px;
  }

  &--tab {
    border-radius: 0;
    height: auto;
    min-height: 25px;
    padding: 8px 5px 10px 5px;

    &:active,
    &.isActive {
      background: #bde1f4;
      color: $backgroundColor;
    }
  }
}

.tabs {
  display: grid;
  grid-auto-flow: column;
  gap: 2px;

  .button {
    &:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
}
