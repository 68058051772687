.melodyVisualiser {
  height: 200px;
  position: relative;

  &--pitchGrid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    pointer-events: none;

    display: flex;
    flex-direction: column;

    background-color: black;

    &Item {
      flex: 1;
      &:nth-child(2n) {
        background-color: #151515;
      }
    }
  }

  canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
