.lfoControlsContainer {
  .lfo {
    padding: 6px 10px 8px 10px;
    background: rgba(white, 0.1);
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .lfoControls {
    display: grid;
    grid-auto-flow: column;
    gap: 10px;
    padding: 10px;
    background: rgba(white, 0.1);
    margin-top: -5px;
    margin-bottom: 5px;

    &--col {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &--target {
      margin-bottom: auto;
    }
    &--retrigger,
    &--sync {
      display: flex;
      padding: 10px 0;
      align-items: center;
      font-size: 12px;
    }

    &--modulationKnobs {
      display: grid;
      grid-auto-flow: column;
      gap: 10px;
      margin-top: 10px;
    }
  }
}
