@import url("https://use.typekit.net/ugu1iba.css");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");

@mixin adwarStyles {
  --font-size: 10px;
  --font-body: urw-din, sans-serif;
  --font-heading: lisbeth-display, sans-serif;

  --blue: #002356;
  --green: #94cba0;
  --purple: #251b34;
  --peach: #f4a88d;
  --red: #ff4141;
  --yellow: #f2d96d;
  --white: #ffffff;

  --midground: #1c2230;
  --foreground: #6f80a7;
  --lowlight: #1a6d99;
  --highlight: #bde1f4;
  --muted: #888b91;

  --border: rgba(139, 144, 157, 0.2);

  --black: #0a0b0d;
  --white: #ffffff;
  --blue-light: #c3e0f3;
  --blue-dark: #3c73f3;
  --grey: #8b909d;

  --unit: 40px;
  --margin: 8px;
  --unit-margin: 56px;

  --header: hsl(259, 4%, 10%);
  --main: var(--black);
  --background: var(--black);

  --tab: var(--track-inner);
  --tab-highlight: var(--grey);
  --tab-menu: var(--track-inner);

  --tooltip: var(--blue-light);
  --tooltip-text: var(--black);

  --text: var(--white);
  --text-muted: #888b91;

  --disabled: #7e8187;

  --track: hsl(259, 4%, 10%);
  --track-inner: hsl(259, 4%, 15%);
  --playhead: var(--blue-light);
  --pitch-label: var(--grey);
  --pitch-label-highlight: var(--white);
  --pitch-marker: var(--grey);
  --pitch-marker-highlight: var(--white);
  --pitch-marker-trigger: var(--blue-dark);
  --pitch-marker-trigger-highlight: var(--blue-dark);
  --pitch-marker-role: var(--white);
  --button-muted: var(--grey);
  --button-highlight: var(--white);

  --radius: calc(var(--unit) / 2);
}
