@import "adwarStyles";

.adwarEditor {
  @include adwarStyles;

  position: absolute;

  bottom: 0;
  left: 0;
  right: 0;
  height: 260px;

  z-index: 3;

  padding: 20px 20px;

  background-color: #111;
  border-top: 1px solid rgba(white, 0.1);

  .closeButton {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}
