@import "./variables.scss";

.apotome {
  .track {
    width: 330px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: rgba(#090909, 1);
    border-radius: 5px;
    box-shadow: 0 0 15px 0 rgba(black, 0.5);
    color: white;

    .trackHeader {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 15px 15px 0 15px;

      &--top {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 1fr repeat(5, auto);
        gap: 5px;
        padding-bottom: 16px;

        h2 {
          display: block;
          margin: 0;
          font-size: 18px;
          color: white;
        }

        .button {
          width: 20px;
          height: 20px;
          border-radius: 20px;
          padding: 2px;
          background: rgba(white, 0.2);
          transition: background-color 0.15s ease;
          color: black;
          font-weight: 500;
          cursor: pointer;

          &:hover {
            background: rgba(white, 0.3);
          }

          &.isActive {
            background: #bde1f4;

            .icon {
              fill: black;
            }
          }

          .icon {
            fill: white;
          }

          &--trackRandomize {
            width: auto;
            padding: 0 6px;
            color: white;
            font-size: 10px;
            &:active {
              background-color: white;
            }
          }

          &--trackMute {
            .iconMuted {
              display: none;
            }

            .iconUnmuted {
              display: block;
            }

            &.isActive {
              .iconMuted {
                display: block;
              }

              .iconUnmuted {
                display: none;
              }
            }
          }
        }
      }

      &.isFollowingTuning {
        .track--tuningSystem--name,
        .track--tuningSystem--name a,
        .track--referencePitch {
          color: rgba($textColor, 0.3);
        }
      }
    }

    .trackSection {
      margin: 0 0 15px 0;

      &Header {
        height: 32px;
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 1fr auto;
        gap: 5px;
        align-items: center;
        background: rgba(white, 0.2);
        padding: 8px 10px 10px 10px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        h4 {
          display: inline-block;
          font-size: 10px;
          font-weight: 600;
          letter-spacing: 0.5px;
          text-align: left;
          color: #ddd;
          margin: 0;
        }

        .button {
          padding: 0;
          margin: 0;
          background: none;
          text-transform: none;
          border-radius: 15px;
          font-size: 11px;
          font-weight: 500;
          color: #ddd;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }

        .button--manageTuningSnapshots {
          float: right;
          height: auto;
        }

        .followToggle {
          display: flex;
          align-items: center;
          font-size: 11px;
          line-height: 1;
          font-weight: 500;
          color: rgba(white, 0.5);

          .toggleSwitch {
            margin-left: 5px;
          }
        }
      }

      &Body {
        background: rgba(white, 0.1);
        padding: 10px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      &Subheader {
        height: 32px;
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 1fr auto;
        gap: 5px;
        align-items: center;
        padding: 0 0 10px 0;

        h5 {
          display: inline-block;
          font-size: 10px;
          font-weight: 600;
          letter-spacing: 0.5px;
          text-align: left;
          color: #ddd;
          margin: 0;
        }

        .followToggle {
          display: flex;
          align-items: center;
          font-size: 11px;
          line-height: 1;
          font-weight: 500;
          color: rgba(white, 0.5);

          .toggleSwitch {
            margin-left: 5px;
          }
        }
      }
    }

    .trackBody {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding: 0 15px;

      &.isFollowingAllowedIntervals {
        .melodicIntervalToggle--label,
        .track--melody--forcePolyphonyControl {
          color: rgba($textColor, 0.3);
        }
      }
      &.isFollowingBeatDivisions {
        .track--beatDivisionRestToggles,
        .track--forceBeatTupletsControl,
        .track--euclideanRange,
        .track--euclideanBeatValue {
          color: rgba($textColor, 0.3);
        }
      }
    }

    .track--activeScaleWeightsControls {
      margin-bottom: 10px;
    }

    .trackSection {
      &--tuningAndSubset {
        .track {
          &--tuningSystem {
            padding: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &--name {
              display: inline-block;
              max-width: 225px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: $textColor;
            }

            &--changeButton {
              margin: 0;
            }
          }

          &--referencePitch {
            margin: 10px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;
          }

          &--scale {
            margin: 10px 0;
            width: 100%;
            .scalePicker {
              width: 100%;
              max-width: initial;
            }
          }
        }
      }

      &--intervalWeights,
      &--octaveWeights {
        position: relative;
      }

      &--switchVisibleArticulationWeights,
      &--switchActiveArticulation {
        float: right;
      }

      .track--voiceCountControl {
        .select {
          display: inline-flex;
          margin: 0 5px;
          width: 70px;
        }
      }

      .track--melody--allowedIntervals {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 5px;
      }

      .track--melody--forcePolyphonyControl {
        display: block;
        margin: 10px 0;
      }

      .track--melody--shapeGamut {
        margin: 5px 0;
        font-size: 10px;
        .select {
          display: inline-flex;
          margin-left: 5px;
          width: 70px;
        }
      }

      &--activeBeatDivisionControls {
        margin-bottom: 10px;
      }

      &--useAccentControl {
        .trackSectionHeader {
          border-radius: 5px;
        }
      }

      .track--beatDivisionRestToggles {
        margin: 5px 0 10px 0;
        font-size: 10px;
        text-align: center;

        &-inputs {
          display: flex;
          justify-content: space-between;
          .toggleButton {
            margin: 0 0 3px 0;
          }
        }
      }

      .track--euclideanControls {
        display: flex;
        flex-direction: row;
      }
      .track--euclideanInputs {
        flex: 1;
        padding-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }

      .track--euclideanRange,
      .track--euclideanBeatValue {
        margin-top: 5px;
        font-size: 10px;
        text-align: center;
        .select {
          display: inline-flex;
          margin-left: 5px;
          width: 70px;
        }
      }

      .track--noteLengthRange {
        &--labels {
          display: flex;
          justify-content: space-between;
          font-size: 10px;
          margin-top: 5px;
        }
      }

      &--beatDelayRange {
        font-size: 10px;
        text-align: center;
      }

      .track--forceBeatTupletsControl {
        display: block;
        margin-top: 5px;
        font-size: 10px;
        font-weight: normal;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .track--adwarTuningSystem {
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--name {
          display: inline-block;
          max-width: 225px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: $textColor;
        }

        &--changeButton {
          margin: 0;
        }
      }

      .track--adwarScale {
        margin: 10px 0;
        width: 100%;
        .scalePicker {
          width: 100%;
          max-width: initial;
        }
      }

      .track--noteEchoControls {
        display: grid;
        grid-auto-flow: column;
        gap: 5px;
      }

      .track--toneFilterControls {
        display: grid;
        grid-auto-flow: column;
        gap: 5px;
      }

      &--mixControls {
        .trackSectionBody {
          display: grid;
          grid-auto-flow: column;
          gap: 5px;
        }
      }
    }
    &--instrumentControl {
      .midiOutput .inputGroup > * {
        margin: 5px 0;
      }
    }
  }
}
